<template>
    <comp-form title="创建畅联组" :forms="structure" ref="comp_form" id-key="appId" height="auto" width="450px" @on-submit="onSubmit"></comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"

export default {
    components: { CompForm },

    data() {
        return {
            structure: [
                {
                    type: "input",
                    name: "groupName",
                    title: "畅联组名称",
                    required: true,
                },
            ],
        }
    },

    props: {
        appId: {
            type: String,
            default: "",
        },
    },

    methods: {
        /**
         * 打开表单窗口
         */
        display(id) {
            this.$refs.comp_form.open(id)
        },

        /**
         * 重置事件
         */
        onSubmit(evt) {
            const fd = evt.value

            this.$post(`/gateway/api/fastDev/UserGroupController/createByAppId`, {
                appId: this.appId,
                groupName: fd.groupName,
                groupType: 22,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code !== "200") {
                    return this.$Message.error(res.desc || res.msg || "系统繁忙，请稍后再试！")
                }

                this.$Message.success("创建成功！")

                this.$emit("on-success", {
                    tag: "CommunicationGroup",
                    value: {
                        groupName: fd.groupName,
                        groupId: res.data,
                    },
                })
            })
        },
    },
}
</script>
